import { Box, Button } from '@mui/material'
import React from 'react'
import { ChisiamoStyle } from './Style'
// DiscoverButton component with a call-to-action to explore more publications

const DiscoverButton = ({align}) => {
    return (
        <Box sx={ChisiamoStyle(align).pubbox}>
            <Button
                variant="contained"
                sx={ChisiamoStyle().discoverbtn}
                onClick={() => window.open("https://storesportivi.it/abbonamenti-e-riviste-digitali-2024/", "_blank")}
            >
                Scopri le nostre testate
            </Button>
        </Box>
    )
}

export default DiscoverButton