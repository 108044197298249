import { Box, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import Animation from './Animation'
import { AboutStyle } from '../Style'
const images = [
    { src: require("../../assests/updateimages/2.jpg") },
    { src: require("../../assests/updateimages/3.jpg") },
    { src: require("../../assests/updateimages/1.jpg") },
    { src: require("../../assests/updateimages/4.jpg") },
    { src: require("../../assests/updateimages/5.jpg") },
    { src: require("../../assests/updateimages/6.jpg") },
    { src: require("../../assests/updateimages/7.jpg") },
    { src: require("../../assests/updateimages/9.jpg") },
    { src: require("../../assests/updateimages/8.jpg") },
    { src: require("../../assests/updateimages/11.jpg") },
    { src: require("../../assests/updateimages/12.jpg") },
    { src: require("../../assests/updateimages/13.jpg") },
    { src: require("../../assests/updateimages/10.jpg") },
    { src: require("../../assests/updateimages/14.jpg") },
    { src: require("../../assests/updateimages/15.jpg") },
    { src: require("../../assests/updateimages/16.jpg") },
    { src: require("../../assests/updateimages/17.jpg") },
    { src: require("../../assests/updateimages/18.jpg") },
]

const About = ({ recent, isMoved, handlehistory, isMobile,isIpad }) => {
    
    return (
        <Box
            sx={AboutStyle(isMoved).outerbox}
        >
            <Box sx={AboutStyle(isMoved).box}>
                <Box sx={AboutStyle(isMoved).innerbox}>
                    {recent && <IconButton onClick={handlehistory} sx={AboutStyle(isMoved).icon}>
                        <img src={require('../../assests/history.png')} width={"90px"}
                            height={"90px"} />
                    </IconButton>
                    }
                    <img src={require("../../assests/Logoblack.png")} alt="Sportivi Logo" style={AboutStyle(isMoved, isMobile,isIpad).img} />
                </Box>
                <Animation isMoved={isMoved} images={images} speed={40000} isMobile={isMobile} isIpad={isIpad} />
            </Box>
        </Box>)
}

export default About