import React from "react";
import { Box } from "@mui/material";
import DiscoverButton from "../Components/DiscoverButton";
import AboutSection from "../Components/Chisiamo/AboutSection";
import Publication from "../Components/Chisiamo/Publication";

// List of publication details including names, logos, and links
const publications = [
    { name: "Il Nuovo Calcio", logo: require("../assests/1.png"), link: " https://storesportivi.it/prodotto/il-nuovo-calcio-2/" },
    { name: "Scienza & Sport", logo: require("../assests/2.png"), link: 'https://storesportivi.it/prodotto/scienza-sport/' },
    { name: "Triathlete", logo: require("../assests/3.png"), link: "https://storesportivi.it/prodotto/triathlete-2/" },
    { name: "Correre", logo: require("../assests/4.png"), link: "https://storesportivi.it/prodotto/correre/" },
];



// Main Chisiamo component combining all sections
const Chisiamo = () => {
    return (
        <Box sx={{ pt: 8 }}>
            <AboutSection />
            <Publication publications={publications} />
            <DiscoverButton align={"center"} />
        </Box>
    );
};

export default Chisiamo;
