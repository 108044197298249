import { keyframes } from '@mui/material';
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
const isAndroid = /Android/.test(navigator.userAgent);


export const headerStyle = (isMobile) => {
    return {
        AppBar: {
            padding: "5px 0px",
            paddingRight: "20px",
            margin: "10px",
            borderRadius: "50px",
            "&.MuiAppBar-root": {
                backgroundColor: "white",
                width: { xs: "95%", sm: "98%", md: "98.5%" },
                position: 'fixed',
                zIndex: 999
            },

        },
        navLinks: {
            display: "flex",
            gap: "100px",
            flexGrow: 1,
            justifyContent: "flex-end",
            "@media (max-width: 900px)": {
                gap: "50px",
            },
            "@media (max-width: 600px)": {
                display: "none",
            },
        },
        navButton: {
            "&.MuiButton-root": {
                color: "black !important",
                fontSize: "26px",
                textTransform: "none",
                ml: 10,
                pr: 4,
                transform: "scale(1.5, 0.7)"
            },
            "@media (max-width: 600px)": {
                fontSize: "0.8rem",
            },
        },
        iconButton: {
            width: "45px",
            backgroundColor: "white",
            height: "50px",
            "&:hover": {
                backgroundColor: "white",
            },
        },
        toolbar: {
            alignItems: "center",
            paddingLeft: "15px",
            paddingRight: isMobile ? "5px" : "95px",
            marginLeft: "10px",
            "&.MuiToolbar-root": {
                minHeight: "40px",
                minWidth: "90%",
                display: "flex !important",
                justifyContent: "space-between !important",
            }
        },
        menuButton: {
            color: "black",
            display: "none",
            "@media (max-width: 600px)": {
                display: "block",
            },
        },
        link: {
            color: "black",
            "&:hover": {
                color: "white",
                background: "linear-gradient(to right, rgb(53, 66, 255) 0%, #B87DFF, rgb(85, 247, 255) 100%)",
                borderRadius: "5px",
                fontWeight: "bold",
            },
        },
    }
}


export const HomeStyle = (isMoved, isIpad) => {
    return {
        outerbox: { textAlign: 'center', p: { xs: 0, md: 4 } },
        innerbox: {
            transform: isMoved ? { xs: 'translateY(-180px)', md: 'translateY(-230px)' } : 'translateY(0)', // Animation effect
            transition: 'transform 0.5s ease-in-out', // Smooth transition
            maxHeight: { xs: "auto", lg: "75vh" }
        },
        pubbox: {
            display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "center", lg: "none" },
            justifyContent: "center", gap: 2, my: 4, mt: isMoved ? 0 : { xs: 0, md: 9 },
        },
        logo: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: "pointer",
            borderRadius: 2,
            textAlign: "center",
            width: { xs: '90%', sm: "100%" },
            cursor: "pointer",
            backgroundColor: "#ffffff", // White background
            color: "#000000", // Black text
            fontFamily: "'Times New Roman', serif", // Similar to the text style in the image
            textTransform: "none", // Prevents all caps transformation
            borderRadius: "20px", // Rounded edges for the button
            padding: "10px 20px", // Adjust the padding as needed
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for a subtle 3D effect
            fontSize: "1.2rem", // Adjust font size
            "&:hover": {
                backgroundColor: "#f0f0f0", // Slightly darker on hover
            },
            p: 3,
            mt: 0
        },
        discoverbtn: { textAlign: "start", pb: isMoved ? 0 : 10 },
        btn: {
            background: 'linear-gradient(90deg, #00A9FF, #A3EBFF)',
            borderRadius: '20px',
            padding: '10px 20px',
            cursor: "pointer"
        },
        bottombox: { display: { xs: 'none', lg: "flex" }, width: "100%", justifyContent: "space-around", pb: 10 },
        leftimg: {
            width: "150px",
            display: "flex",
            zIndex: -1
        },
        rightimg: {
            width: "350px",
            height: "40px",
            display: "flex",
            zIndex: -1,
            marginTop: "70px",
        },
        dotsimg: {
            width: "300px",
            display: isMoved ? "none" : "flex",
            position: "absolute",
            zIndex: -1,
            right: '4.5%',
            bottom: isIpad ? "-17%" : '-30%',
            transform: isMoved ? 'translateY(-50px)' : 'translateY(0)', // Animation effect
            transition: 'transform 0.5s ease-in-out', // Smooth transition
        }
    }
}

export const AboutStyle = (isMoved, isMobile, isIpad) => {
    return {
        outerbox: {
            textAlign: "center",
            zIndex: 999,
            pt: 10,
        },
        box: { margin: "0 auto" },
        innerbox: { display: "flex", justifyContent: isMoved ? "space-between" : "center", width: isMoved ? { xs: "52%", sm: "100%", md: "52%", lg: '52%' } : '100%' },
        icon: {
            display: 'flex',
            p: 0, "&:hover": {
                backgroundColor: "white",
            }, height: '120px', ml: 2
        },
        img: { width: 200, margin: "20px 0px 40px 0px", transform: isMoved ? isMobile ? 'translateX(0px)' : isIpad ? 'translateX(-282px)' : 'translateX(-450px)' : 'translateX(0)', },
        vdobox: {
            transform: isMoved ? 'translateY(650px)' : 'translateY(0)', // Animation effect
            transition: 'transform 0.5s ease-in-out', // Smooth transition
            maxHeight: isMoved ? "150px" : "165px",
            bgcolor: "transparent"
        },
        video: {
            width: '100%',
            borderRadius: '10px',
            objectFit: 'cover',
            border: "none",
            maxHeight: isMoved ? "156px" : "165px",

        },
    }

}

export const AnimationStyle = (isMoved, isIpad, isMobile) => {
    return {
        div: {
            transform: isMoved
                ? isIOS
                    ? isIpad ? isMobile ? "translateY(490%)" : 'translateY(580%)' : 'translateY(500%)'
                    : isMobile?'translateY(500%)':'translateY(350%)'
                : 'translateY(0)',
            transition: 'transform 0.5s ease-in-out', // Smooth transition
            marginBottom: "10px"
        }
    }

}


const gradientReveal = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const gradientReveal1 = keyframes`
  0% {
    opacity: 0;
    min-width:280px;
  }
  100% {
    opacity: 0;
    min-width:280px
  }
`;
// Keyframes for fade in and out, without resizing the width
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1;transform:translateX(-123px) }
  100% { opacity: 0; transform:translateX(-123px)}
`;


const fadeInInnerContent = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideInAndGrow = keyframes`
  0% {
    transform: translateY(-50px);
    font-size: 16px;
    opacity: 1;
     position: relative;
    bottom: 40px;
  }
  100% {
    transform: translateY(10px) scale(1);
    font-size: 24px;
    opacity: 1;
 position: relative;
    bottom: 0px;
        z-index: 99999;
    padding-left: 17px;
    height:10px;
  }
`;

const slideOutAndGrow = keyframes`
  0% {
    transform: translateY(20px) scale(1);
    font-size: 24px;
    opacity: 1;
    z-index: 99999;
    padding-left: 17px;
  }
  100% {
    transform: translateY(-50px);
    font-size: 16px;
    opacity: 1;
    position: relative;

  }
`;


export const ChronologyStyle = (isMoved) => ({
    outerbox: (history) => ({
        display: history ? { xs: "none", md: 'flex' } : { xs: "none", md: "flex" }, flexDirection: history ? "row" : "column", width: { xs: isMoved ? "80%" : "23%", md: "37%", lg: 'auto' },
    }),
    box: {
        margin: "0 auto"
    },
    innerbox: (history) => ({
        display: history ? "block" : "flex",
        justifyContent: isMoved ? "space-between" : "center",
        width: isMoved ? "53%" : '100%',
    }),
    icon: {
        display: { xs: "none", md: 'flex' },
        p: 0,
        height: '120px',
        ml: 2
    },
    img: {
        width: 200,
        margin: "20px 0px 40px 0px"
    },
    title: (history) => ({
        mb: 2,
        color: '#333',
        animation: `${history ? slideInAndGrow : slideOutAndGrow} 1s ease forwards`,
        textAlign: "start",
        position: "relative",
    }),
    historyBox: (history) => ({
        position: 'absolute',
        width: { xs: 200, lg: 310 },
        height: isMoved ? 470 : "auto",
        display: history ? 'block' : "flex",
        p: 1,
        bgcolor: 'white',
        borderRadius: 6,
        boxShadow: 4,
        overflow: 'hidden',
        fontFamily: 'Arial, sans-serif',
        animation: history ? `${gradientReveal} 2s ease forwards` : `${gradientReveal1} 0s ease forwards`,
    }),
    gradientOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to right, #3542FF 0%, #55F7FF 100%)',
    },
    innerContent: {
        position: 'relative',
        zIndex: 1,
        background: 'white',
        borderRadius: 4,
        pl: 1,
        pr: 1,
        width: '100%',
        pt: 7,
        opacity: 0,
        animation: `${fadeInInnerContent} 1s ease 0.5s forwards`,
        height: '100%',
    },
    button: (item) => ({
        display: 'flex',
        justifyContent: 'left',
        boxShadow: '1px 3px 3px 0px #00000026',
        bgcolor: 'white',
        mt: 1,
        background: item.clicked
            ? 'linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box'
            : 'linear-gradient(#fff, #fff) padding-box, linear-gradient(#EBEEFF, #EBEEFF) border-box',
        border: '2px solid',
        borderRadius: '10px',
        borderColor: '#02010100',
        p: 1,
        width: '100%',
        textTransform: 'none',
        "&:hover": {
            background: 'linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box',
            border: '2px solid',
            borderRadius: '10px',
            borderColor: '#02010100',
        },
        "&:active": {
            background: 'linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box',
            border: '2px solid',
            borderRadius: '10px',
            borderColor: '#02010100',
        },
    }),
    buttonText: {
        ml: 1,
        textAlign: 'left',
        overflow: 'hidden',
        maxWidth: '500px',
        textOverflow: 'ellipsis',
        color: '#000000',
        fontSize: { xs: '16px', md: '14px' },
    },
    emptyBox: (history) => ({
        display: history ? "flex" : { xs: "none", md: 'flex' },
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
        maxWidth: 350,
        minWidth: { md: 240, lg: history ? 0 : 350 },
        height: isMoved ? 400 : history ? 50 : 100,
        zIndex: -1,
        animation: history ? `${fadeOut} 0s ease forwards` : `${fadeIn} 0s ease forwards`, // Fading in or out based on history

    })
});

export const ChatStyle = (message, isFocused, recent, expand, item, isIpad, isMobile, history, chatMessages, index) => {
    return {
        box: { display: "flex", mt: 2 },
        main: {
            display: recent ? "flex" : 'block', flexDirection: "column", justifyContent: recent ? "center" : 'none', width: { xs: "100%", md: isFocused ? "76%" : expand ? "100%" : "52%" }, ml: { xs: 0 }, position: "relative", top: message && { xs: "-45px", md: '-165px' }
        },
        card: {
            width: message ? '100%' : { xs: '100%', md: '45vw' }, boxShadow: 3, borderRadius: "60px", background:
                "linear-gradient(to right, rgb(53, 66, 255) 0%,#B87DFF, rgb(85, 247, 255) 100%)", mr: 6,
            transform: isFocused ? { xs: "none", md: "translateX(95px)" } : "none", height: "auto"
        },
        cardcontent: {
            "&.MuiCardContent-root": {
                p: { xs: "11px", md: "16px" },
                pb: { xs: "11px", md: '16px ' }
            }
        },
        icon: {
            width: "100%", p: 0, pb: expand ? 2 : 0,
            ":focus": {
                outline: "none"
            }
        },

        iconbox: {
            bgcolor: "white"
            , borderRadius: "40px", p: 2, pt: 3, pb: 3,
            boxShadow: 3, width: "100%", display: "flex",
            textAlign: "start",
            justifyContent: "space-between", alignItems: "center"

        },
        search: {
            fontSize: "18px",
            lineHeight: 1.1,
            transform: 'scaleX(1.05)',
            ml: 2,
            width: { xs: "80%", md: "100%" }
        },
        collapsebox: {
            backgroundImage: "linear-gradient(to right,#E9EBFF, #F6F2FF,#EEFDFF)",
            height: { xs: "auto", md: "70vh" }
            , borderRadius: "40px", p: 2, boxShadow: 3, mt: 2,
        },
        chatbox: {
            overflowY: "scroll",
            minHeight: { xs: isAndroid ? "550px" : "480px", md: "462px", lg: "400px" },
            height: { xs: isAndroid ? "calc(90vh - 245px)" : "calc(90vh - 275px)", md: "250px" },
            "&::-webkit-scrollbar": {
                width: "5px", // Adjust scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#282c34", // Color of the scroll thumb
                borderRadius: "10px", // Optional: add rounded corners to thumb
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent", // Scrollbar track background
            },
        },
        innerbox: { display: "flex", height: "auto", mt: 2 },
        imgbox: {
            bgcolor: "white",
            borderRadius: "50%",
            width: item?.reply !== "" ? { xs: "40px", md: "50px" } : { xs: "40px", sm: "40px", md: "50px" },
            height: item?.reply !== "" ? { xs: "40px", md: "50px" } : { xs: "40px", md: "50px" },
            p: item?.reply !== "" ? isIpad ? 1.2 : isMobile ? "7px" : 1.4 : 0.6,
            pt: item?.reply !== "" ? { xs: "6px", sm: 1.4, md: 1.5 } : 0.6
        },
        powered: {
            width: { xs: "100%", lg: expand ? "100%" : "90%" }, display: 'flex', justifyContent: "center", position: "relative", bottom: '5px',
            transform: message ? "translateX(-120px)" : "none",

        },
        
        img: { width: item?.reply !== "" ? isMobile || isIpad ? "22px" : "30px" : isMobile ? "30px" : isIpad ? "33px" : "40px", height: item?.reply !== "" ? isMobile || isIpad ? "20px" : "30px" : isMobile ? "30px" : isIpad ? "40px" : "40px" },

        grad: {
            background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
            border: "2px solid",
            borderRadius: "10px",
            borderColor: " #02010100",
            width: "10%",
            minWidth: "950px",
            ml: 2,
            maxWidth: "100%", // Default max width
            height: "fit-content",
        },
        grad1: {
            background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
            border: "2px solid",
            borderRadius: "10px",
            borderColor: " #02010100",
            width: "91%",
            ml: 2,
            maxWidth: "100%", // Default max width
            height: "fit-content",
            wordBreak: "break-word", pr: 2,

        },
        text: { ml: 1, textAlign: "start", mt: 1.5, mb: 1.5, fontSize: { xs: "14px", md: "1rem" } },
        loadbox: { display: "flex", height: "auto", mt: 2, },
        loadertext: { mt: 1.4, mb: 1.4, ml: 1, fontSize: { xs: "14px", md: "1rem" }, color: "gray", textAlign: "start" },
        rightbox: { display: !isFocused ? { xs: "none", md: 'flex' } : "none", justifyContent: message ? "end" : "center", alignContent: 'center', width: "10%", flexWrap: "wrap", pt: recent ? 20 : 10, ml: 10 },
        overflow: {
            maxWidth: "90%", // Width when overflow occurs
        },
        logo: {
            color: "#343568",
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: 700,
        },
        logo1: {
            color: "#343568",
            fontSize: { xs: "14px", md: "18px" },
            fontWeight: 600,
        },
        button: {
            borderRadius: "50%",
            bgcolor: message?.length === 0 ? "#C8C8C8" : "#343568",
            height: { xs: "30px", md: "40px" },
            width: { xs: "30px", md: "40px" },
            "&.MuiButton-root": { minWidth: "30px" },
            "&.MuiButton-root:hover": {
                borderRadius: "50%",
                bgcolor: "#343568",
            },
        },
        input: {
            border: "none",
            bordeRadius: "50px",
            mt: 2,
            "& .MuiInputBase-root": {
                background: isFocused
                    ? "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box"
                    : "linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, #3542FF 0%, #55F7FF 100%) border-box",
                border: isFocused ? "none" : "2px solid",
                bordeRadius: "50px",
                borderColor: " #02010100",
                p: { xs: "10px 10px", md: "15px 15px" }

            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    bordeRadius: "50px",
                    borderColor: "transparent", // Removes the border
                    p: { xs: "10px 10px", md: "auto" }
                },
            },
        },
        drawer: {
            backgroundImage: "linear-gradient(to right,#E9EBFF, #F6F2FF,#EEFDFF)",
            height: { xs: "calc(100vh)", md: "calc(100vh - 237px)" },
            width: "300px",
            borderRadius: "10px",
        },
        typobox: { display: "flex", justifyContent: "space-between", p: "10px" },
        heading: { textTransform: "uppercase", color: "#343568", fontSize: { xs: "16px", md: "20px" }, fontWeight: 700 },
        iconbtn: {
            border: "1px solid #FF6151",
            "&.MuiIconButton-root": {
                p: { xs: "3px", md: "5px" },
            },
        },
    }
};


export const ChisiamoStyle = (align, isMobile) => ({
    card: { textAlign: "center", zIndex: 999 },
    box: { margin: "0 auto", pt: 1, },
    iconbox: { width: '90%', },
    img: { width: 200, margin: " 20px 0px 40px 0px" },
    grid: { boxShadow: "inset 5px 5px 20px #00000029, 5px 5px 20px #00000029", minHeight: "440px", p: { xs: 2, lg: 10 }, display: "flex", justifyContent: 'space-evenly' },
    imggrid: { flexBasis: { xs: "100%", md: "50%" }, display: "flex", alignItems: "center" },
    imgcard: { background: "linear-gradient(to right, #f26d5d, #b7e295,#ffff)", borderRadius: "20px", p: { xs: 0, md: 5 }, width: { xs: "100%", lg: "90%" } },
    grid2: { width: { xs: "100%", md: "50%" }, m: { xs: 2, md: 0 }, display: "flex", justifyContent: "center", flexDirection: "column", p: 2 },
    typo: { fontWeight: "bold", color: "#333", textAlign: "left", fontSize: { xs: "18px", md: "20px" } },
    para: { mt: 2, color: "#666", textAlign: "justify", fontWeight: "bold" },
    pubbox: { my: 4, textAlign: align, maxHeight: { xs: "auto", md: "400px" } },
    img1: {
        width: "150px",
        display: "flex",
        position: "relative",
        bottom: "6vh",
        left: "7%",
        zIndex: -1
    },
    img2: {
        width: "300px",
        display: "flex",
        position: "relative",
        bottom: "-9vh",
        left: "10%",
        zIndex: -1
    },
    img3: {
        width: "300px",
        display: "flex",
        position: "relative",
        bottom: "5vh",
        left: "40%",
        zIndex: -1
    },
    img4: {
        width: "150px",
        display: "flex",
        position: "relative",
        bottom: "14vh",
        left: "182vh",
        zIndex: -1
    },
    pubinnerbox: {
        display: "flex",
        pl: { xs: 0, md: 5, lg: 18 },
        justifyContent: "space-between",
        position: "relative",
        bottom: { xs: 0, lg: "47vh" }
    },
    imgbtn: {
        borderRadius: 2,
        textAlign: "center",
        width: { xs: '90%', sm: "100%" },
        cursor: "pointer",
        m: 3,
        backgroundColor: "#ffffff", // White background
        color: "#000000", // Black text
        fontFamily: "'Times New Roman', serif", // Similar to the text style in the image
        textTransform: "none", // Prevents all caps transformation
        borderRadius: "20px", // Rounded edges for the button
        padding: "10px 20px", // Adjust the padding as needed
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for a subtle 3D effect
        fontSize: "1.2rem", // Adjust font size
        "&:hover": {
            backgroundColor: "#f0f0f0", // Slightly darker on hover
        },
        p: 3
    },
    pubimg: { width: "70%", height: "80px" },
    discoverbtn: {
        background: "linear-gradient(to right, rgb(53, 66, 255), #B87DFF, rgb(85, 247, 255))",
        color: "white",
        fontWeight: "bold",
        px: 5,
        py: 1,
        textTransform: "none",
        fontSize: "16px",
        borderRadius: 10,
        "&:hover": {
            background: "linear-gradient(to right, rgb(53, 66, 255), #B87DFF, rgb(85, 247, 255))",
        },
    }
})


// Define styles for the footer component
export const footerStyles = {
    container: {
        backgroundColor: "#202d59",
        color: "#D0D0D0",
        padding: 4,
    },
    logoSection: {
        width: "150px",
        marginBottom: "20px",
    },
    logobox: { display: "flex", justifyContent: { xs: 'center', md: 'left' } },
    logogrid: { width: { xs: "100%", md: "10%" }, display: 'flex', alignItems: { xs: 'center', md: 'left' }, flexDirection: { xs: "column", md: "row" } },
    grid: { width: { xs: "100%", md: "30%" }, display: 'flex', alignItems: { xs: 'center', md: 'left' }, flexDirection: { xs: "column" } },
    sections: { display: "flex", flexBasis: { xs: "100%", md: "75%" }, justifyContent: "space-between", mt: 3 },
    text: {
        mb: 1, width: { xs: "100%", md: "60%" }, textAlign: 'center', fontSize: "24px", color: "#D0D0D0", lineHeight: 1.2
    },
    paymentSect: { width: { xs: "100%", md: "50%" } },
    text1: {
        mb: 1, width: "100%", textAlign: 'center', fontSize: "24px", color: "#D0D0D0", lineHeight: 1.2
    },
    button: {
        backgroundColor: "#3F5CC3",
        color: "white",
        borderRadius: 5,
        textTransform: "none",
        width: "70%",
        "&:hover": {
            backgroundColor: "#3F5CC3 ",
        },
    },
    paymentImage: {
        width: "70%",
    },
    footerLink: {
        marginBottom: 0.5,
        fontSize: "16px",
        LineHeight: 1
    },
    footerDetails: {
        marginTop: 4,
        p: 2, display: { xs: "block", md: "flex" }, textAlign: { xs: 'center', md: "start" }, width: { xs: "100%", md: 'auto' }
    },
    text2: { fontSize: "18px" },
    title: { fontSize: "24px", mb: 1 },
    footerDetailsbox: { display: { xs: "flex", md: "block" }, justifyContent: { xs: "center", md: "flex-start" }, width: { xs: "100%", md: 'auto' }, flexDirection: { xs: "column", md: "flex-start" } }
};

