import { Box, Card, Grid2, IconButton, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ChisiamoStyle } from '../Style';

// AboutSection component displays the main "Chi Siamo" content with description and images
const AboutSection = () => {
    const navigate = useNavigate();
    return (
        <Card sx={ChisiamoStyle().card}>
            <Box sx={ChisiamoStyle().box}>
                {/* Logo with navigation link to home page */}
                <Box sx={ChisiamoStyle().iconbox}>
                <IconButton onClick={() => navigate("/")}>
                    <img src={require("../../assests/Logoblack.png")} alt="Sportivi Logo" style={ChisiamoStyle().img} />
                </IconButton>
                </Box>
             

                {/* Grid structure for logo and description text */}
                <Grid2 container sx={ChisiamoStyle().grid}>
                    <Grid2 sx={ChisiamoStyle().imggrid}>
                        <Card sx={ChisiamoStyle().imgcard}>
                            <img src={require("../../assests/Logo.png")} width={"80%"} />
                        </Card>
                    </Grid2>
                    <Grid2 sx={ChisiamoStyle().grid2}>
                        <Typography variant="h4" sx={ChisiamoStyle().typo}>
                            CHI SIAMO
                        </Typography>
                        <Typography sx={ChisiamoStyle().para}>
                            Le pubblicazioni sportive, da oltre quarant'anni, sono ciò che ci contraddistingue, con proposte moderne realizzate da esperti del settore.
                            <br /><br />
                            Ci occupiamo di corsa, calcio, triathlon, fitness, preparazione fisica, mentalità di allenamento e tutto ciò che può aiutare il semplice appassionato, l’atleta professionista e l’allenatore ad avere informazioni precise concernenti l’attività sul campo.
                            <br /><br />
                            I nostri prodotti prevedono riviste, libri, video, ebook, eventi di aggiornamento in presenza, tutorial realizzati da esperti con attenzione ai particolari, per percorsi di formazione su misura e di assoluto valore.
                        </Typography>
                    </Grid2>
                </Grid2>
            </Box>
        </Card>
    )
}

export default AboutSection