import { Box, Button, SwipeableDrawer, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import Chat from '../Components/Home/Chat';
import About from '../Components/Home/About';
import { HomeStyle } from '../Components/Style';
import DiscoverButton from '../Components/DiscoverButton';
import { useTheme } from '@mui/styles';

const publications = [
  { name: "Scienza & Sport", logo: require("../assests/2.png"), link: "https://storesportivi.it/prodotto/scienza-sport/" },
  { name: "Il Nuovo Calcio", logo: require("../assests/1.png"), link: 'https://storesportivi.it/prodotto/il-nuovo-calcio-2/' },
  { name: "Triathlete", logo: require("../assests/3.png"), link: "https://storesportivi.it/prodotto/triathlete-2/" },
  { name: "Correre", logo: require("../assests/4.png"), link: "https://storesportivi.it/prodotto/correre/" },
];

const Home = () => {
  const theme = useTheme();
  const [isMoved, setIsMoved] = useState(false); // State to track if AboutSection is moved
  const [recent, setRecent] = useState(false); // State to track if AboutSection is moved
  const [history, sethistory] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("lg"));
  const handlehistory = () => {
    sethistory(!history)
  };
  const handleSearchClick = () => {
    setIsMoved(!isMoved); // Toggle AboutSection position
    setRecent(!recent)
  };

  return (
    <>


      {/* Animated AboutSection */}
      <About isMoved={isMoved} recent={recent} handlehistory={handlehistory} history={history} isIpad={isIpad} isMobile={isMobile}/>

      <Box sx={HomeStyle(isMoved).outerbox}>
        {/* Top Navigation Icons */}
        <Box sx={HomeStyle(isMoved).innerbox}>
          <Chat handleSearchClick={handleSearchClick} isMoved={isMoved} recent={recent} history={history} handlehistory={handlehistory} />
        </Box>

        {/* Logos Section */}
        <Box sx={HomeStyle(isMoved).pubbox}>
          {publications.map((item) => (
            <Box
              key={item.name}
              elevation={3}
              sx={HomeStyle(isMoved).logo}
              onClick={() => window.open(item.link, "_blank")}
            >
              <img src={item.logo} width='100%' height='80px' alt={item.name} />
            </Box>
          ))}
        </Box>

        {/* Discover Button */}
        <DiscoverButton align={isMobile ? "center" : "start"} />
        {isMoved && <Box sx={HomeStyle(isMoved).bottombox}>
          <Box></Box>
          <img src={require("../assests/left.png")} style={HomeStyle(isMoved).leftimg} alt='left' />
          <img src={require("../assests/right.png")} style={HomeStyle(isMoved).rightimg} alt='right' />
        </Box>}
        {/* Background Dots */}
        {!isMobile && <img src={require("../assests/dots.png")} style={HomeStyle(isMoved, isIpad).dotsimg} alt="Background Dots" />}
      </Box>
    </>
  );
};

export default Home;
