import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { Grid2 } from "@mui/material"; // Import Grid2
import { footerStyles } from "./Style";

const Footer = () => {
    return (
        <Box sx={footerStyles.container}>
            <Grid2 container spacing={2} display={"flex"} justifyContent="space-between" mt={2}>
                {/* Logo and Contact Section */}
                <Grid2 xs={12} md={3} sx={footerStyles.logogrid}>
                    <Box sx={footerStyles.logobox}>
                        <img src={require("../assests/Logo.png")} alt="Sportivi Logo" style={footerStyles.logoSection} />
                    </Box>
                </Grid2>
                <Grid2 container sx={footerStyles.sections}>
                    {/* Newsletter Section */}
                    <Grid2 xs={12} md={3}sx={footerStyles.grid}>
                        <Typography variant="h6" sx={footerStyles.text}>
                            Clicca per iscriverti alla newsletter
                        </Typography>
                        <Button variant="contained" sx={footerStyles.button}>
                            Rimani aggiornato
                        </Button>
                    </Grid2>

                    {/* Payment Methods Section */}
                    <Grid2 xs={12} md={3} sx={footerStyles.paymentSect}>
                        <Typography variant="h6" sx={footerStyles.text1}>
                            Metodi di pagamento
                        </Typography>
                        <Box display="flex" justifyContent="center" gap={1}>
                            <img src={require("../assests/cards.png")} alt="cards" style={footerStyles.paymentImage} />
                        </Box>
                    </Grid2>
                </Grid2>
            </Grid2>
            {/* Footer Details and Links */}
            <Grid2 container spacing={2} justifyContent="space-between" sx={footerStyles.footerDetails}>
                <Box sx={footerStyles.footerDetailsbox}>
                    <Typography variant="body2" sx={footerStyles.text2}>Via caldera 21, 20153</Typography>
                    <Typography variant="body2" sx={footerStyles.text2}>P.IVA 09855010154</Typography>
                    <Typography variant="body2" sx={footerStyles.text2}>+39 024815396</Typography>
                    <Typography variant="body2" sx={footerStyles.text2}>serviziolettori@sportivi.it</Typography>
                </Box>

                {/* Footer Links */}
                {[
                    { title: "Aiuto", links: ["Domande frequenti", "Metodi di pagamento", "Tempi di spedizione"] },
                    { title: "Area Clienti", links: ["Il mio profilo", "Stato degli ordini", "Wishlist"] },
                    { title: "Policy", links: ["Accedi/Registrati", "Informativa", "Newsletter"] },
                    { title: "Informazioni", links: ["Chi siamo", "Contatti", "FAQ"] },
                ].map((section) => (
                    <Grid2 xs={6} sm={3} md={2} key={section.title}>
                        <Typography variant="h6" sx={footerStyles.title}>
                            {section.title}
                        </Typography>
                        {section.links.map((link) => (
                            <Typography variant="body2" sx={footerStyles.footerLink} key={link}>
                                <Link href="#" underline="none" color="inherit">
                                   -  {link}
                                </Link>
                            </Typography>
                        ))}
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    );
};

export default Footer;
