import React from 'react';
import { AnimationStyle } from '../Style';

function ImageSection({ images, speed }) {
    const imagesStyle = {
        animation: `swipe ${speed}ms linear infinite`,
    };

    return (
        <div className='images' style={imagesStyle}>
            {images.map((image, index) => {
                // Set width based on the index using a repeating pattern
                const widths = [200, 150, 300];
                const width = widths[index % widths.length];

                return (
                    <div className='image' key={index} style={{ margin: 4 }}>
                        <img src={image.src} alt={image.name} style={{ borderRadius: "20px", maxHeight: "150px", maxWidth: `500px` }} />
                    </div>
                );
            })}
        </div>
    );
}

function Animation({ images, speed, isMoved, isIpad, isMobile }) {
    return (
        <div className='banner-wrapper' style={AnimationStyle(isMoved, isIpad, isMobile).div}>
            <div className='wrapper'>
                <ImageSection images={images} speed={speed} />
                <ImageSection images={images} speed={speed} />
            </div>
        </div>
    );
}

export default Animation;
