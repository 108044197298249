import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily:'Eurostile, Roboto Condensed, Arial, sans-serif'  // Set Roc Grotesk as the default font
  },
  palette: {
    primary: {
      main: '#87CEFA', // Example color (you can adjust to your needs)
    },
    secondary: {
      main: '#8a2be2',
    },
  },
});

export default theme;
