import React, { useEffect } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Index from './Routes';
import { ThemeProvider } from '@mui/material';
import theme from './Components/theme';
import "./App.css";
import { storage } from './Storage';

const App = () => {
  useEffect(() => {
    // Clear storage on every refresh
    storage.destory.chathistory();

    // Optional: Add listener for tab/browser close
    const handleBeforeUnload = () => {
      storage.destory.chathistory();
    };

    // Add event listener for tab/browser close
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <Index />
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;
