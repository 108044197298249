import { Button, Grid2, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { ChisiamoStyle } from '../Style';
import { useTheme } from '@mui/styles';
// PublicationsSection displays a grid of publication logos with links

const Publication = ({ publications }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints?.down("lg")); // Check if the viewport is mobile

    return (
        <Box className="test" sx={ChisiamoStyle(isMobile).pubbox}>
            {/* Decorative images for non-mobile view */}
            {!isMobile && (
                <>
                    <img src={require("../../assests/left.png")} style={ChisiamoStyle(isMobile).img1} />
                    <img src={require("../../assests/dots.png")} style={ChisiamoStyle(isMobile).img2} />
                    <img src={require("../../assests/3color.png")} style={ChisiamoStyle(isMobile).img3} />
                    <img src={require("../../assests/left.png")} style={ChisiamoStyle(isMobile).img4} />
                </>
            )}
            <Box sx={ChisiamoStyle().pubinnerbox}>
                {/* Grid to display publication logos as clickable buttons */}
                <Grid2 container columnSpacing={7} width={"100%"}>
                    {publications.map((pub) => (
                        <Grid2 item xs={6} sm={4} key={pub.name}sx={{display:"flex",flexBasis:{xs:"100%",md:"45%"},justifyContent:"center"}}>
                            <Button
                                sx={ChisiamoStyle().imgbtn}
                                onClick={() => window.open(pub.link, "_blank")} // Open publication link in a new tab
                            >
                                <img src={pub.logo} alt={`${pub.name} logo`} style={ChisiamoStyle().pubimg} />
                            </Button>
                        </Grid2>
                    ))}
                </Grid2>
            </Box>
        </Box>
    );
}

export default Publication