import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Button,
    Menu,
    MenuItem,
    useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { headerStyle } from "./Style";
import { useTheme } from "@mui/styles";


const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        navigate(path);
        handleMenuClose();
    };

    return (
        <AppBar position="static" sx={headerStyle().AppBar}>
            <Toolbar sx={headerStyle(isMobile).toolbar}>
                <IconButton
                    edge="start"
                    sx={headerStyle().iconButton}
                    onClick={() => navigate("/")}
                >
                    {/* <HomeOutlinedIcon sx={{
                        fontSize: "35px"
                    }} /> */}
                    <img src={require("../assests/icon.png")} width={"60px"}/>
                </IconButton>

                {!isMobile && (
                <Box className={headerStyle().navLinks}>
                    <Button sx={headerStyle().navButton} onClick={() => navigate("/chisiamo")}>
                        Chi siamo
                    </Button>
                    <Button sx={headerStyle().navButton} onClick={() => window.open("https://storesportivi.it/", "_blank")}>
                        Store Sportivi
                    </Button>
                </Box>)}

                {isMobile && (
                    <IconButton
                        sx={headerStyle().menuButton}
                        color="inherit"
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
            </Toolbar>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleNavigate("/chisiamo")} sx={headerStyle().link}>
                    Chi siamo
                </MenuItem>
                <MenuItem
                    onClick={() => window.open("https://storesportivi.it/", "_blank")}
                    sx={headerStyle().link}
                >
                    Store Sportivi
                </MenuItem>
            </Menu>
        </AppBar>
    );
};

export default Header;
