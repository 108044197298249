import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";

const TypingText = ({ text, chatEndRef, setCheckTypingStatus, textBoxRefs, index, handleScroll, autoScroll }) => {

  const [displayedText, setDisplayedText] = useState("");
  const textRef = useRef(""); // Ref to accumulate text
  const prevTextRef = useRef(""); // Ref to keep track of the previous text
  const scrollTimeoutRef = useRef(null); // Ref to handle delayed auto-scroll re-enabling

  // Utility function to replace Unicode escape sequences
  const replaceUnicodeEscapes = (str) => {
    return str.replace(/\\u([0-9A-Fa-f]{4})/g, (match, group) => {
      return String.fromCharCode(parseInt(group, 16));
    });
  };


  useEffect(() => {
    const targetElement = textBoxRefs.current[index];
    if (targetElement && autoScroll) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo(window.scrollX, window.scrollY);

    }
    handleScroll()

  }, [displayedText]);

  useEffect(() => {
    // Initialize typing animation
    const currentText = text;
    const previousText = prevTextRef.current;

    // Find the overlap between the previous text and the current text
    let overlapLength = 0;
    const maxOverlap = Math.min(currentText.length, previousText.length);

    for (let i = 0; i < maxOverlap; i++) {
      if (currentText.slice(0, i + 1) === previousText.slice(-i - 1)) {
        overlapLength = i + 1;
      }
    }

    // Start typing from the end of the previous text
    const startIndex = overlapLength;
    textRef.current = previousText.slice(0, startIndex); // Resume from where we left off

    setDisplayedText(textRef.current);
    setCheckTypingStatus(false);

    let index = startIndex;
    const intervalId = setInterval(() => {
      if (index < currentText.length) {
        textRef.current += currentText[index];
        const updatedText = replaceUnicodeEscapes(textRef.current);
        setDisplayedText(updatedText);
        index++;
      } else {
        clearInterval(intervalId);
        setCheckTypingStatus(true);

      }
    }, 10); // Adjust typing speed here

    // Update the previous text reference to the current text
    // prevTextRef.current = currentText;

    return () => clearInterval(intervalId);
  }, [text, setCheckTypingStatus]);

  return <ReactMarkdown className="reduce-padding">{displayedText}</ReactMarkdown>;
};

export default TypingText;