import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Chisiamo from "../Pages/Chisiamo";

const Index = () => {
    return (
        <>
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="chisiamo" element={<Chisiamo />} />
            </Routes>
        </>
    );
};

export default Index;
