import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { ChronologyStyle } from '../Style';

function ChronologyCard({ recentList, handleClick, history, recent,handleDelete }) {
  const styles = ChronologyStyle(recent);

  return (
    <Box sx={styles.outerbox(history)}>
      {recent && <Typography
        variant="h6"
        sx={styles.title(history)}
      >
        Cronologia
      </Typography>}
      {history&& <IconButton
                sx={{
                  border: "1px solid #FF6151",
                  "&.MuiIconButton-root": {
                    p: { xs: "3px", md: "5px" },zIndex:99,height:"30px",position:"relative",left:{xs:"28%",md:"9%",lg:"55%"},top:"3%"
                  },
                }}
                disabled={recentList.length === 0 ? true : false}
                onClick={handleDelete}>
                <DeleteIcon sx={{ color: "#FF6151", fontSize: { xs: "14px", md: "18px" } }} />
              </IconButton>}
      <Box sx={styles.historyBox(history)}>
        {/* Gradient overlay */}
        <Box sx={styles.gradientOverlay} />

        {/* Inner content with fade-in delay */}
        <Box sx={styles.innerContent}>
          {recentList?.length ? (
            recentList.map((item, index) => (
              <Button
                key={index}
                sx={styles.button(item)}
                onClick={() => handleClick(item.index, index)}
              >
                <img src={require('../../assests/message.png')} alt="messageImg" />
                <Typography sx={styles.buttonText}>
                  {item.text}
                </Typography>
              </Button>
            ))
          ) : (
            <Box>Nessun dato</Box>
          )}
        </Box>
      </Box>
      <Box sx={styles.emptyBox(history)}>
        <img src={require("../../assests/left.png")} width="110px" height="110px" alt='left' />
      </Box>
    </Box>
  );
}

export default ChronologyCard;
