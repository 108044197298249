import axios from "axios";
import { Box, TextField, Card, CardContent, InputAdornment, IconButton, Collapse, Typography, useMediaQuery, Button, SwipeableDrawer } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { storage } from '../../Storage';
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from 'react';
import { useTheme } from "@mui/material/styles";
import TypingText from './Typingtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../fontAwesomeConfig";
import ChronologyCard from './ChronologyCard';
import { ChatStyle, ChronologyStyle } from "../Style"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
function Chat({ recent = false, handleSearchClick, history, handlehistory }) {
  const theme = useTheme();
  const chatEndRef = useRef(null);
  const textBoxRefs = useRef([]);

  // State for storing chat-related information
  const [message, setMessage] = useState("");
  const [asynReply, setAsynReply] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [recentList, setRecentList] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [deleteclick, setDeleteClick] = useState(false);
  const [checkTypingStatus, setCheckTypingStatus] = useState(false);
  const [visible, setVisible] = useState(false);
  const [lastquery, setlastquery] = useState("");
  const [consistencyOutput, setconsistencyOutput] = useState('');
  const [requestContextOutput, setrequestContextOutput] = useState('');
  const [autoScroll, setAutoScroll] = useState(true); // Track auto-scroll state

  // Determine screen size for responsive styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("lg"));
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isAndroid = /Android/.test(navigator.userAgent);
  // Load initial data (welcome message, streaming settings) when the component mounts
  useEffect(() => {
    window.scroll(0, 0)
    const fetchData = async () => {
      try {
        const res = await axios.get("https://worx-assistant-function-app.azurewebsites.net/api/identify?clientKey=EXT_CLIENT_000021", {
          headers: { Accept: "application/json" },
          withCredentials: true,
        });
        setWelcomeMessage(res.data?.welcome_message?.replace(/\\n/g, '\n'));
        setLoading(false);
        storage.set.chatToken(res.data?.authToken)
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchData();
  }, [deleteclick]);

  // Scroll chat to the bottom on new messages
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollTo(0, chatEndRef.current.scrollHeight - chatEndRef.current.clientHeight);
    }
    storage.set.chat(chatMessages);
    handleScroll()
    setAutoScroll(true)

  }, [chatMessages]);

  // Add welcome message or async replies to chat messages
  useEffect(() => {
    if (welcomeMessage && expand) {
      setCheckTypingStatus(false);
      setChatMessages([{ image: require("../../assests/w-logo 1@2x.png"), mess: "", reply: welcomeMessage }]);
      handleScroll()
      setWelcomeMessage(null);
    }
  }, [welcomeMessage, expand]);

  useEffect(() => {
    // Attach scroll listener
    if (chatEndRef.current) {
      chatEndRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      // Cleanup scroll listener
      if (chatEndRef.current) {
        chatEndRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {

    const { scrollTop, scrollHeight, clientHeight } = chatEndRef.current;

    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
    const isAtBottom1 = scrollTop + clientHeight >= scrollHeight - 2;

    setVisible(!isAtBottom);

    // Disable auto-scroll when user manually scrolls
    if (!isAtBottom1) {
      setAutoScroll(false);
    } else {
      setAutoScroll(true);
    }
  }


  // Toggle focus state for input
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  // Send a message to the server and update chat state
  const sendMessage = async (data) => {
    try {
      const res = await axios.post("https://worx-assistant-function-app.azurewebsites.net/api/queryAssistantProxy", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // withCredentials: true,
      });
      setAsynReply(res.data.reply);
      setChatMessages(prev => [...prev, { image: require("../../assests/w-logo 1@2x.png"), mess: "", reply: res.data.reply }]);
      setLoading(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Handle chat expansion and search click
  const handleExpand = () => {
    if (!expand) {
      setExpand(true);
      handleSearchClick();
    }
  };

  // Process server streaming response, updating chat progressively
  const processAndStoreStream = async (data) => {
    Object.assign(data, { authToken: storage.fetch.chatToken() });

    try {
      const response = await fetch('https://worx-assistant-async-function-app.azurewebsites.net/api/queryAssistantProxyStream', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
      });

      if (response && response.body && typeof response.body.getReader === 'function') {
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let accumulatedReply = "";

        // Read stream chunks
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            accumulatedReply += chunk;

            const replyValue = chunk.match(/"reply":\s*"([^"]*)"/)?.[1];
            if (replyValue) {
              setAsynReply(accumulatedReply);
              setChatMessages(prevChatMessages => [
                ...prevChatMessages,
                { image: require("../../assests/w-logo 1@2x.png"), mess: "", reply: accumulatedReply.replace(/\\n/g, '\n') }
              ]);
            }
          }
        }

        setCheckTypingStatus(true);
      } else {
        console.error("Stream response error");
      }
    } catch (error) {
      console.error("Error processing stream:", error);
      setLoading(false);
    }
  };

  // Main function to handle chat message submission
  const handleSearch = () => {
    const history = storage.fetch.chathistory()
    let hist
    if (history !== undefined) {
      if (history === null) {
        hist = [{
          "inputs": {
            "fetched_docs_ids": [],
            "query": `${lastquery}`
          },
          "outputs": {
            "consistency_output": `${consistencyOutput}`,
            "reply": `${asynReply}`,
            "request_context_output": `${requestContextOutput}`
          }
        }]
        storage.set.chathistory(hist)
        setconsistencyOutput("")
        setrequestContextOutput("")
      } else {
        hist = [...history, {
          "inputs": {
            "fetched_docs_ids": [],
            "query": `${lastquery}`
          },
          "outputs": {
            "consistency_output": `${consistencyOutput}`,
            "reply": `${asynReply}`,
            "request_context_output": `${requestContextOutput}`
          }
        }]
        storage.set.chathistory(hist)
        setconsistencyOutput("")
        setrequestContextOutput("")
      }
    } else {
      hist = [{
        "inputs": {
          "fetched_docs_ids": [],
          "query": `ciao`
        },
        "outputs": {
          "consistency_output": `Greeting - ciao`,
          "reply": `Ciao! Sono Sporty, il tuo giornalista virtuale di fiducia!`,
          "request_context_output": `ciao`
        }
      }]
      storage.set.chathistory(null)
    }


    setRecentList((prevRecentList) => {
      // Update all items in recentList to set clicked to false
      return prevRecentList.map((item) => {
        return { ...item, clicked: false };
      });
    });
    if (!checkTypingStatus || message.trim() === "") return;

    setLoading(true);
    const data = {
      query: message,
      chat_history: hist,
      fetched_docs_ids: [],
      authToken: storage.fetch.chatToken()
    };

    // Add user message to chat
    setChatMessages([...chatMessages, { image: require("../../assests/user.png"), mess: message, reply: "" }]);
    setMessage(""); // Clear input field

    // Update recent messages list
    setRecentList(prevRecentList => [
      { image: require("../../assests/user.png"), text: message, clicked: true, index: chatMessages.length },
      ...prevRecentList,
    ]);

    if (process.env.REACT_APP_STREAM_ENABLED) {
      processAndStoreStream(data);
    } else {
      sendMessage(data);
    }
  };

  // Submit message on "Enter" key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  // Scroll to chat history on item click
  const handleClick = (index, i) => {
    const targetElement = textBoxRefs.current[index];
    if (isMobile) {
      handlehistory()
    }
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo(window.scrollX, window.scrollY);
    }
    setRecentList((prevRecentList) =>
      prevRecentList.map((item, ind) => ({
        ...item,
        clicked: ind === i,
      }))

    );

  };


  //Handle clear chat history
  const handleDelete = () => {
    setChatMessages([]);
    setRecentList([]);
    handleExpand()
    setDeleteClick(true)
    if (isMobile) {
      handlehistory()
    }
  };

  const scrollDown = () => {
    chatEndRef.current.scrollTo({
      top: chatEndRef.current.scrollHeight,
      behavior: "smooth"
    })
    setVisible(false)
  }
  const resp = isMobile || isIpad
  return (
    <Box>
      {history && resp && (
        <SwipeableDrawer
          onClose={handlehistory}
          open={history}
        >
          <Box
            sx={ChatStyle().drawer}>
            <Box sx={ChatStyle().typobox}>
              <Typography sx={ChatStyle().heading}>Chronology</Typography>
              <IconButton
                sx={{
                  border: "1px solid #FF6151",
                  "&.MuiIconButton-root": {
                    p: { xs: "3px", md: "5px" },
                  },
                }}
                disabled={recentList.length === 0 ? true : false}
                onClick={handleDelete}>
                <DeleteIcon sx={{ color: "#FF6151", fontSize: { xs: "14px", md: "18px" } }} />
              </IconButton>
            </Box>
            <Box
              className="chatbox"
              sx={ChatStyle().chatbox}>
              {recentList?.length === 0 ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <Typography sx={{ fontSize: { xs: "16px", md: "18px" } }}>Nessun dato </Typography>
                </Box>
              ) : (
                recentList?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      ml: 2,
                      position: "relative",
                      mt: 1,
                      width: "100%",
                      boxShadow: "1px solid gray",
                      pr: 0
                    }}
                    style={{ marginLeft: 0, marginTop: "5px" }}>
                    <Button
                      sx={ChronologyStyle().button(item)}
                      onClick={() => handleClick(item.index, index)}>
                      <img src={require("../../assests/message.png")} alt="messageImg" />
                      <Typography sx={ChronologyStyle().buttonText}>
                        {item.text}
                      </Typography>
                    </Button>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
      <Box sx={ChatStyle().box}>
        <ChronologyCard recentList={recentList} handleClick={handleClick} history={history} recent={recent} handleDelete={handleDelete} />

        {/* Main Content Area */}
        <Box sx={ChatStyle(recent, history, "", expand).main}>
          <Card sx={ChatStyle(recent, history).card}>
            <CardContent sx={ChatStyle(history).cardcontent}>
              <IconButton onClick={handleExpand} sx={ChatStyle(expand).icon}>
                <Box sx={ChatStyle().iconbox}>
                  <Typography sx={ChatStyle().search}>Ciao sono Sporty, Chiedimi su calcio, running, triathlon e scienza&sport</Typography>
                  <img src={require("../../assests/searchicon.png")} width={"30px"} />
                </Box>
              </IconButton>
              <Collapse in={expand} timeout={500}>
                <Box sx={ChatStyle().collapsebox}>
                  <Box className="chatbox" sx={ChatStyle().chatbox} ref={chatEndRef}>
                    {chatMessages.map((item, index) => (
                      <Box sx={ChatStyle().innerbox} key={index}>
                        <Box sx={ChatStyle("", "", "", "", item, isIpad, isMobile).imgbox}>
                          <img style={ChatStyle("", "", "", "", item, isIpad, isMobile).img} src={item.image} alt="icon" />
                        </Box>
                        <Box ref={(el) => (textBoxRefs.current[index] = el)} sx={{
                          ...ChatStyle().grad1,
                          mb: !checkTypingStatus && chatMessages?.length - 1 == index ? 5 : 0

                        }}>
                          <Box sx={ChatStyle().text}>
                            {item.reply !== "" ?
                              <TypingText text={item?.reply} textBoxRefs={textBoxRefs} setVisible={setVisible} autoScroll={autoScroll} handleScroll={handleScroll} index={index - 1} chatEndRef={chatEndRef} setCheckTypingStatus={setCheckTypingStatus} checkTypingStatus={checkTypingStatus} />
                              :
                              <Typography sx={{ ml: 1, mt: 1.4, mb: 1.4, fontSize: { xs: "14px", md: "1rem" } }}>{item.mess}</Typography>
                            }
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    {loading && (
                      <Box sx={ChatStyle().innerbox} >
                        <Box sx={ChatStyle().imgbox}>
                          <img style={ChatStyle("", "", "", "", "", isIpad, isMobile).img} src={require("../../assests/w-logo 1@2x.png")} alt="icon" />
                        </Box>
                        <Box sx={ChatStyle().grad1}>
                          <Box sx={ChatStyle().text}>
                            <Typography sx={ChatStyle().loadertext}>Sporty sta scrivendo...</Typography>

                          </Box>
                        </Box>
                      </Box>
                    )}
                    <ArrowDownwardIcon
                      onClick={scrollDown}
                      style={{
                        display: visible ? 'flex' : 'none',
                        background: '#4cd964',
                        borderRadius: '50%',
                        color: 'white',
                        cursor: 'pointer',
                        position: "fixed",
                        bottom: isMobile ? isAndroid ? "220px" : "180px" : history ? "110px" : "185px",
                        left: isMobile ? isAndroid ? "180px" : "184px" : history ? "540px" : isIpad ? "383px" : "895px",
                        zIndex: 1000
                      }}
                    />
                  </Box>

                  <TextField
                    autoFocus
                    fullWidth
                    multiline
                    maxRows={5}
                    placeholder="Inserisci messaggio..."
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled={!message} sx={ChatStyle(message).button} onClick={handleSearch}>
                            <FontAwesomeIcon icon={["far", "paper-plane"]} color="white" />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    sx={ChatStyle(message, isFocused).input}
                  />
                </Box>
              </Collapse>
            </CardContent>
          </Card>
          <Box sx={ChatStyle(history, "", "", expand).powered}>
            <img src={require("../../assests/powered.png")} width={"150px"}
              height={"50px"} />
          </Box>

        </Box>
        <Box sx={ChatStyle(history, expand).rightbox}>
          <img src={require("../../assests/right.png")} width={isIpad ? "250px" : "370px"}
            height={recent ? "auto" : "43px"} />
        </Box>
      </Box>
    </Box>
  );
}

export default Chat;
