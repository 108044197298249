const LS_KEY = {
  chat: "chat",
  chatToken: "chatToken",
  chathistory:"chathistory"
};
const set = {
  chat: (data) => {
    localStorage.setItem(LS_KEY.chat, JSON.stringify(data));
  },
   chatToken: (data) => {
    localStorage.setItem(LS_KEY.chatToken, data);
  },
  chathistory: (data) => {
    localStorage.setItem(LS_KEY.chathistory, JSON.stringify(data));
  },
};
const fetch = {
  chat: () => {
    const data = localStorage.getItem(LS_KEY.chat);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  chatToken: () => {
    const data = localStorage.getItem(LS_KEY.chatToken);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  chathistory: () => {
    const data = localStorage.getItem(LS_KEY.chathistory);
    
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded;
      } catch (err) {}
    }
  },
};
const destory = {
  chathistory: () => {
    localStorage.removeItem(LS_KEY.chathistory);
  },
};
export const storage = {
  set,
  fetch,
  destory,
};
